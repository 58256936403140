import React from 'react';
import './Sponsors.css';
import PageWrapper from './components/layout/PageWrapper';
import Sponsor, { SponsorshipLevel } from './components/sponsors/Sponsor';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadManipulator from './components/util/HeadManipulator';


function Sponsors() {

    return (

        <PageWrapper>
            <HeadManipulator pageName='Sponsors' canonicalPath='/sponsors' />
            <div className="Sponsors">
                <h1>Sponsors</h1>

                <div>
                    <h2>2024 Race Sponsors</h2>
                    <Button><Link to="/sponsor-info">Become a 2024 Race Sponsor!</Link></Button>

                    <div className="sponsor-level olympic-sponsor-level">
                        <h3>Olympic Sponsors: $1,500 and up</h3>
                        <Row>
                            {sponsorsArray.filter((s) => s.level === SponsorshipLevel.Olympic).map((s) => (
                                <Col lg={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={s.level} sponsorName={s.name} sponsorLink={s.link} logoSrc={s.logoSrc} logoAltText={s.logoAltText} backgroundColor={s.backgroundColor} extraPadding={s.extraPadding} /></Col>
                            ))}
                        </Row>
                    </div>

                    <div className="sponsor-level elite-sponsor-level">
                        <h3>Elite Sponsors: $1,000 and up</h3>
                        <Row>
                            {sponsorsArray.filter((s) => s.level === SponsorshipLevel.Elite).map((s) => (
                                <Col lg={4} sm={6} xs={12} className="mx-auto"><Sponsor sponsorshipLevel={s.level} sponsorName={s.name} sponsorLink={s.link} logoSrc={s.logoSrc} logoAltText={s.logoAltText} backgroundColor={s.backgroundColor} extraPadding={s.extraPadding} /></Col>
                            ))}
                        </Row>
                    </div>

                    <div className="sponsor-level champion-sponsor-level">
                        <h3>Champion Sponsors: $750 and up</h3>
                        <Row>
                            {sponsorsArray.filter((s) => s.level === SponsorshipLevel.Champion).map((s) => (
                                <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={s.level} sponsorName={s.name} sponsorLink={s.link} logoSrc={s.logoSrc} logoAltText={s.logoAltText} backgroundColor={s.backgroundColor} extraPadding={s.extraPadding} /></Col>
                            ))}
                        </Row>
                    </div>

                    <div className="sponsor-level marathon-sponsor-level">
                        <h3>Marathon Sponsors: $500 and up</h3>
                        <Row>
                            {sponsorsArray.filter((s) => s.level === SponsorshipLevel.Marathon).map((s) => (
                                <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={s.level} sponsorName={s.name} sponsorLink={s.link} logoSrc={s.logoSrc} logoAltText={s.logoAltText} backgroundColor={s.backgroundColor} extraPadding={s.extraPadding} /></Col>
                            ))}

                        </Row>
                    </div>

                    <div className="sponsor-level sprinter-sponsor-level">
                        <h3>Sprinter Sponsors: $250 and up</h3>
                        <Row>
                            {sponsorsArray.filter((s) => s.level === SponsorshipLevel.Sprinter).map((s) => (
                                <Col xxl={3} xl={4} lg={6} className="mx-auto"><Sponsor sponsorshipLevel={s.level} sponsorName={s.name} sponsorLink={s.link} logoSrc={s.logoSrc} logoAltText={s.logoAltText} backgroundColor={s.backgroundColor} extraPadding={s.extraPadding} /></Col>
                            ))}
                        </Row>
                    </div>

                    <div>
                        <h3>Other Sponsor Recognition</h3>
                        <ul>
                            {sponsorsArray.filter((s) => !s.level).map((s) => (
                                <li>{s.name}</li>
                            ))}
                        </ul>
                    </div>

                </div>

                <div>
                    <Row className="mt-5">
                        <Col className="mx-auto sponsors-thank-you textbox">
                            <div className='text-center mt-2'>
                                <img className="optimistlogo sponsor-optimistlogo" src="images/OptimistLogo.jpg" alt="Winter Haven Optimist Club logo" />
                            </div>
                            <div className="mt-2">
                                <p>Many heart-felt thanks to our wonderful sponsors. Because of you, we are able to support more youth throughout our community. Santa&apos;s Run Fest is only one of many events that the Optimist Club of Winter Haven performs. Again............ THANK YOU!!!</p>
                                <p>If you or your company are interested in sponsoring, see the <Link to="../sponsor-info">Become a Sponsor page</Link>.</p>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>

        </PageWrapper>
    );
}

export default Sponsors;

interface SponsorModel {
    name: string;
    level?: SponsorshipLevel;
    link?: string;
    logoSrc?: string;
    logoAltText?: string;
    extraImagePadding?: boolean;
    imageBackgroundColor?: boolean;
    extraPadding?: boolean;
    backgroundColor?: boolean;
}

const sponsorsArray: SponsorModel[] = shuffle<SponsorModel>([
    {
        level: SponsorshipLevel.Olympic,
        name: "Central Buick GMC",
        link: "https://www.centralbuickgmctruck.com/",
        logoSrc: "images/sponsor-logos/CentralBuickGMC_logo.png",
        logoAltText: "Central Buick GMC logo"
    },
    {
        level: SponsorshipLevel.Olympic,
        name: "Paradise Oaks RV Resort",
        link: "https://www.paradiseoaksrv.com/",
        logoSrc: "images/sponsor-logos/ParadiseOaks.jpg",
        logoAltText: "Paradise Oaks RV Resort logo"
    },
    {
        level: SponsorshipLevel.Elite,
        name: "Low Payment Kings powered by Mahalak Auto Group",
        link: "http://low-payment-kings.com",
        logoSrc: "images/sponsor-logos/mahalak-auto-group.png",
        logoAltText: "Low Payment Kings powered by Mahalak Auto Group logo",
        backgroundColor: true
    },
    {
        level: SponsorshipLevel.Elite,
        name: "Redeemer Winter Haven",
        link: "https://www.redeemerwinterhaven.org/",
        logoSrc: "images/sponsor-logos/RedeemerWH_2024.png",
        logoAltText: "Redeemer Winter Haven logo"
    },
    {
        level: SponsorshipLevel.Elite,
        name: "Heartland Church",
        link: "https://heartchurch.org/",
        logoSrc: "images/sponsor-logos/Heartland-Church-v2.png",
        logoAltText: "Heartland Church logo"
    },
    {
        level: SponsorshipLevel.Champion,
        name: "Little Wolfe's Pet Resort",
        link: "https://www.littlewolfes.com/",
        logoSrc: "images/sponsor-logos/little-wolfes-logo.png",
        logoAltText: "Little Wolfe's Pet Resort logo"
    },
    {
        level: SponsorshipLevel.Marathon,
        name: "Straughn & Turner, PA",
        link: 'https://www.straughnturner.com/',
        logoSrc: 'images/sponsor-logos/straughn-and-turner-logo-400w.jpg',
        logoAltText: "Straughn & Turner logo"
    },
    {
        level: SponsorshipLevel.Marathon,
        name: "In Memory of Larry Powell",
        logoAltText: "In Memory of Larry Powell",
        extraPadding: true,
        backgroundColor: true
    },
    {
        level: SponsorshipLevel.Marathon,
        name: "Winter Haven Hospital/BayCare",
        link: "https://baycare.org/locations/hospitals/winter-haven-hospital/patients-and-visitors/",
        logoSrc: "images/sponsor-logos/WinterHavenHospital.jpeg",
        logoAltText: "Winter Haven Hospital logo"
    },
    {
        name: "Florida Dermatology and Skin Cancer Centers"
    },
    {
        name: "Citizen Bank and Trust"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Wawa",
        link: 'https://www.wawa.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Moon Family Dental Care",
        link: 'https://moonfamilydental.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Winter Haven Dermatology",
        link: 'https://www.winterhavenderm.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Stephen F. Baker"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Chevrolet Center",
        link: 'https://chevroletcenter.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Bernadette & John Pilkey"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Family Eye Care",
        link: 'https://drhenne.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Brooks Law Group",
        link: 'https://www.brookslawgroup.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Floyd, Sammons & Spanjers, PA",
        link: 'http://winterhavenlegal.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Lakeland Optimist Club"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Mr. John Crow"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Howell & Thornhill PA",
        link: 'https://howellthornhill.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Ingram Financial Group, Inc.",
        link: 'https://ingramfinancialgroup.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Victor Smith Law Group PA",
        link: 'https://victorsmithlaw.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Dwight H. Pate, DMD, PA",
        link: 'http://www.drpatedmd.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Bill and Donna Jackson"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Keenan's Precision Collision",
        link: 'https://keenansprecisioncollision.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Famous Tate Appliance & Bedding Centers",
        link: 'https://famoustate.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Anytime Fitness",
        link: 'https://www.anytimefitness.com/'
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Robinson Enterprises, Inc."
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "Remedios Besing Family"
    },
    {
        level: SponsorshipLevel.Sprinter,
        name: "B.L. Lanier Fruit Company, Inc."
    }
]);


function shuffle<T>(array: T[]): T[] {
    var m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}
